<template>
  <div class="chat-list">
    <v-card
      v-for="(chat, index) in chats"
      :key="index"
      class="chat-list-item mb-3"
      :class="{active: currentChat && chat.id == currentChat.id && chat.to_support == currentChat.to_support}"
      :link="!!chat.id"
      @click="$emit('changeChat', chat.id, chat.to_support)"
      v-show="chat.is_visible"
      :id="`chat-list-item-${chat.id}`"
      tile>
      <v-card-title>
        <v-col sm="8" class="pa-0" v-if="chat.to_support">Поддержка poiskpl.ru</v-col>
        <v-col sm="8" class="pa-0 chat-searchable" v-else>{{getCompanyName(chat)}}</v-col>
        <v-col sm="4" class="pa-0 text-right request-id" v-if="!chat.to_support">Запрос {{chat.request_id}}</v-col>
      </v-card-title>
      <v-card-subtitle v-if="!chat.to_support" class="chat-searchable">
        <span>{{getUserName(chat)}}</span>
        <v-spacer></v-spacer>
        <v-badge v-if="chat.notViewed" class="mr-4 mt-2" :content="chat.notViewed"></v-badge>
      </v-card-subtitle>
      <v-card-text v-if="chat.last_message">{{chat.last_message.text}}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions v-if="chat.last_message">
        {{formatDate(chat.last_message.created_at)}}
        <v-spacer></v-spacer>
        <span v-if="!chat.last_message.viewed" class="chat-green">Новое</span>
        <span v-if="chat.last_message.viewed" class="chat-grey">Просмотрено</span>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: ['chats', 'currentChat'],

  methods: {
    formatDate(date) {
      return date ? moment(date).format('LLL') : ''
    },

    getUserName(chat) {
      const user = this.$store.state.auth.user.id === chat.ds_manager.id ? chat.lc_manager : chat.ds_manager
      return (user.last_name ? user.last_name + ' ' : '') + user.first_name
    },

    getCompanyName(chat) {
      const user = this.$store.state.auth.user.id === chat.ds_manager.id ? chat.lc_manager : chat.ds_manager
      if (user.dealership) {
        return user.dealership[0].name
      } else {
        return user.leasing_company[0].name
      }
    }
  }
}
</script>
