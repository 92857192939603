<template>
  <v-sheet :height="windowHeight - 566" class="chat-message-sheet">
    <v-card class="chat-message" tile v-for="(item, index) in messages" :key="index" :color="getColor(item)">
      <v-card-title>
        <div v-if="item.to_support">
          <span class="chat-black-101" v-if="fromSupport(item)">Служба поддержки</span>
          <span class="chat-black-101" v-else>Вы</span>
        </div>
        <div v-else>
          <span v-if="item.user_id == $store.state.auth.user.id" class="chat-black-101">Вы</span>
          <span v-else>{{item.user.last_name}} {{item.user.first_name}}</span>
        </div>
        <div class="ml-5 chat-message-date">{{formatDate(item.created_at)}}</div>
        <v-spacer></v-spacer>
        <div v-if="isMy(item)">
          <v-icon color="blue" v-if="item.viewed">mdi-check-all</v-icon>
          <v-icon v-if="!item.viewed">mdi-check-all</v-icon>
        </div>
      </v-card-title>
      <v-card-text class="chat-black">{{item.text}}</v-card-text>
    </v-card>
  </v-sheet>
</template>
<script>
import moment from 'moment'

export default {
  props: ['messages', 'windowHeight'],

  methods: {
    formatDate(date) {
      return moment(date).format('LLL')
    },

    fromSupport(item) {
      const users = Array.isArray(item.to_user_id) ? item.to_user_id : [item.to_user_id]
      return users.includes(this.$store.state.auth.user.id)
    },

    getColor(item) {
      return item.archive_message ? 'red lighten-4' : ''
    },

    isMy(item) {
      if (item.to_support) {
        const hasToUserIds = item.to_user_id && item.to_user_id[0]
        return item.user_id === this.$store.state.auth.user.id && !hasToUserIds
      }
      return item.user_id === this.$store.state.auth.user.id
    }
  }
}
</script>
