<template>
  <div>
    <div class="d-flex mb-5">
      <div class="d-flex flex-column">
        <h5 class="h5-text">Онлайн чат</h5>
      </div>
    </div>
    <v-row>
      <v-col md="3" sm="12" class="page-block flex-column" v-if="chatsVisible">
        <v-slide-x-transition :group="true">
        <div v-if="chats" key="chats">
          <div>
            <ChatSearch @search="search" @clear="clearSearch" :supportChats="false"></ChatSearch>
          </div>
          <ChatList :chats="chats" :currentChat="currentChat" @changeChat="changeChat"></ChatList>
        </div>
        </v-slide-x-transition>
      </v-col>
      <v-col md="6" sm="12" class="page-block flex-column" v-if="messagesVisible">
        <v-slide-x-transition :group="true">
        <div  v-if="currentChat" class="position-relative" v-resize="setHeight" key="messages">
          <h3 class="d-flex chat-messages-header" v-if="currentChat.to_support">
            <v-btn v-if="isMobile" text @click="backToChats()"><v-icon>mdi-chevron-left</v-icon> Поддержка poiskpl.ru</v-btn>
            <span v-if="!isMobile">Поддержка poiskpl.ru</span>
          </h3>
          <h3 class="d-flex chat-messages-header" v-else>
            <v-btn v-if="isMobile" text @click="backToChats()"><v-icon>mdi-chevron-left</v-icon> {{getCompanyName(currentChat)}}</v-btn>
            <span v-if="!isMobile">{{getCompanyName(currentChat)}}</span>
            <v-spacer></v-spacer>
            <v-btn v-if="isMobile" icon @click="toInfo()"><v-icon>mdi-information-outline</v-icon></v-btn>
          </h3>
          <v-divider class="mt-5 mb-5"></v-divider>
            <MessageList :messages="messages" :window-height="windowHeight" ></MessageList>
            <v-form
              class="mt-5"
              @submit.prevent="send"
              ref="messageForm"
              lazy-validation
              v-model="messageFormValid"
            >
              <div>
                <v-textarea
                  :rules="messageRules"
                  v-model="message"
                  rows="3"
                  :counter="charCounter"
                  outlined
                  dense
                  @blur="$refs.messageForm.resetValidation()"
                ></v-textarea>
              </div>
              <div>
                <v-btn color="indigo darken-4" class="white--text" type="submit" large block data-click="send">Отправить сообщение</v-btn>
              </div>
            </v-form>
          <v-overlay :value="loading" absolute>
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
        </div>
        </v-slide-x-transition>
      </v-col>
      <v-col md="3" sm="12" class="page-block77 pt-0 flex-column" v-if="infoVisible">
        <v-slide-x-transition :group="true">
          <div key="info">
            <h3 class="d-flex" v-if="isMobile">
              <v-btn text @click="backToMessages()"><v-icon>mdi-chevron-left</v-icon> Подробнее</v-btn>
            </h3>
            <v-divider v-if="isMobile" class="mb-3"></v-divider>
            <div>
              <v-card v-if="currentChat && currentChat.to_support" class="chat-info" elevation="0" tile>
                <v-card-title>
                  Поддержка poiskpl.ru
                </v-card-title>
              </v-card>
              <v-card v-if="currentChat && !currentChat.to_support" class="chat-info" elevation="0" tile>
                <v-card-title>
                  {{getCompanyName(currentChat)}}
                </v-card-title>
                <v-card-subtitle>
                  {{getUserName(currentChat)}}
                </v-card-subtitle>
                <v-card-text class="chat-black-101">{{getCompanyLocation(currentChat)}}</v-card-text>
              </v-card>
            </div>
            <div class="mt-3">
              <v-btn v-if="currentChat && !currentChat.to_support"  outlined large block @click="getRequestLink()" target="_blank" color="primary">Карточка запроса</v-btn>
              <v-btn v-show="!isArchived" v-if="(currentChat && !currentChat.to_support) || !currentChat" class="mt-3" outlined large block @click="writeToSupport()" color="primary" style="max-width: 100%">Написать в поддержку</v-btn>
            </div>
          </div>
        </v-slide-x-transition>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Vue from 'vue'
// import Component from 'vue-class-component'
import WS from '@/apis/WS'
import { Http } from '@/apis/Http'
import moment from 'moment'
import ChatSearch from '@/views/Chat/components/ChatSearch.vue'
import ChatList from '@/views/Chat/components/ChatList.vue'
import MessageList from '@/views/Chat/components/MessageList.vue'
import CommonMethods from '@/views/Chat/components/CommonMethods'

export default {
  components: { ChatList, MessageList, ChatSearch },
  data() {
    return {
      loading: false,
      charCounter: 1000,
      messageFormValid: true,
      windowHeight: window.innerHeight,
      message: '',
      messages: [],
      currentChat: null,
      chats: [],
      messagesVisible: true,
      infoVisible: true,
      isMobile: false,
      isArchived: 0
    }
  },
  async created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.messagesVisible = false
      this.infoVisible = false
      this.isMobile = true
    }
    this.$setLoading(true)

    if (this.$route.params.chat_id) {
      this.messagesVisible = true
    }

    if (this.$route.path === '/chat') Http.get('/logs/user-enter-chats')

    await this.getChatDetails()
    this.getMessages()
    this.$setLoading(false)

    this.listenOfArchive()

    this.$socket.on('new_message', async (data) => {
      if (this.$route.name !== 'Chat') return
      console.log('nm', data)

      let chat = this.chats.find(el => el.id === data.chat_id)
      if (chat) {
        if (data.chat_id !== this.currentChat.id) ++chat.notViewed
        chat.last_message = {
          id: data.id,
          text: data.text,
          viewed: data.chat_id === this.currentChat.id ? 1 : 0,
          created_at: data.created_at
        }
      } else {
        chat = data
        chat.is_visible = 1
        chat.id = data.chat_id
        chat.last_message = {
          id: data.id,
          text: data.text,
          created_at: data.created_at,
          viewed: 0,
          to_support: data.to_support
        }
        this.chats.splice(0, 0, chat)
        if (!this.currentChat) {
          await this.getChatDetails()
          this.getMessages()
        }
      }

      if (this.currentChat && data.chat_id === this.currentChat.id) {
        data.viewed = 1
        this.addMessage(data)
        this.sendNewViewed(data.id)
      }

      if (this.chats.length) this.sortByNew()
    })

    this.$socket.on('is_viewed', (data) => {
      if (this.$route.name !== 'Chat') return
      console.log('isv', data)
      for (const id of data.ids) {
        const message = this.messages.find(el => el.id === id)
        if (message) message.viewed = 1
      }
      this.setLastMessageViewed(data.ids)
    })

    this.$listenDocumentVisibility(() => {
      this.sendAllViewed()
    })
  },

  computed: {
    messageRules() {
      return [
        (v) => !!v || 'Введите сообщение',
        (v) => (v && v.length <= this.charCounter) || `Максимальная длина сообщения ${this.charCounter} символов`
      ]
    },
    chatsVisible() {
      if (!this.isMobile) return true
      return !this.messagesVisible && !this.infoVisible
    }
  },

  methods: {
    setChatUrl() {
      if (this.currentChat.id && this.$router.currentRoute.path !== `/chat/${this.chatId}`) {
        this.$router.push(`/chat/${this.currentChat.id}`)
      }
    },

    sendAllViewed() {
      if (!this.$isDocumentVisible()) return
      const ids = []
      let to = 0
      for (const message of this.messages) {
        if (!message.viewed) {
          ids.push(message.id)
          message.viewed = 1
          to = message.user_id
        }
      }
      if (ids.length) WS.sendMessage('is_viewed', { to_user_id: to, ids: ids })
      this.$store.commit('setViewed', ids)
    },

    sendNewViewed(id) {
      // if (!this.$isDocumentVisible()) return
      let toUserId = null
      if (!this.currentChat.to_support) {
        toUserId = this.$store.state.auth.user.id === this.currentChat.ds_manager.id ? this.currentChat.lc_manager.id : this.currentChat.ds_manager.id
      }
      WS.sendMessage('is_viewed', { to_user_id: toUserId, ids: [id] })
      this.$store.commit('setViewed', [id])
    },

    send() {
      if (!this.$refs.messageForm.validate()) return
      let toUserId = null
      if (!this.currentChat.to_support) {
        toUserId = this.$store.state.auth.user.id === this.currentChat.ds_manager.id ? this.currentChat.lc_manager.id : this.currentChat.ds_manager.id
      }
      const data = {
        id: 0,
        viewed: 0,
        to_support: this.currentChat.to_support,
        text: this.message,
        ds_manager: this.currentChat.ds_manager,
        lc_manager: this.currentChat.lc_manager,
        request_id: this.currentChat.request_id,
        user: this.$store.state.auth.user,
        user_id: this.$store.state.auth.user.id,
        chat_id: this.currentChat.id,
        to_user_id: [toUserId],
        created_at: moment()
      }

      this.addMessage(data)

      Http.post('/chat/store', data).then(response => {
        if (response.data.error) {
          data.user_id = 0
          data.text = response.data.error
          data.archive_message = true
          data.user = {
            first_name: 'Сообщение',
            last_name: ''
          }
          return
        }
        if (!this.currentChat.id) {
          this.setChatId(response.data.chat_id, data.to_support)
        }

        data.id = response.data.message_id
        data.chat_id = response.data.chat_id

        WS.sendMessage('new_message', data)

        this.currentChat.last_message = {
          id: data.id,
          text: data.text,
          viewed: 0,
          created_at: data.created_at
        }
      }).catch(e => console.log(e))

      this.message = ''
      this.$refs.messageForm.resetValidation()
    },

    setChatId(chatId, toSupport = 0) {
      const chat = this.chats.find((el) => !el.id && el.to_support === toSupport)
      if (chat) {
        chat.id = chatId
      }
    },

    getRequestLink() {
      let url = ''
      if (this.$store.state.auth.userRole === 'manager-ds') {
        url = '/request-leas-objects/' + this.currentChat.request_id
      } else {
        url = '/search-leas-objects/' + this.currentChat.request_id
      }
      Http.get('/chat/user-click-request-link', { params: { request_id: this.currentChat.request_id } })
      if (url) window.open(url, '_blank') // this.$router.push(url)
    },

    writeToSupport() {
      const user = this.$store.state.auth.user
      const role = this.$store.state.auth.userRole
      let supportChat = this.chats.find(el => el.to_support === 1)
      if (!supportChat) {
        supportChat = {
          id: 0,
          ds_manager: role === 'manager-ds' ? user : null,
          lc_manager: role === 'manager-lc' ? user : null,
          request_id: null,
          to_support: 1,
          is_visible: 1
        }
        console.log(supportChat)
        this.chats.unshift(supportChat)
        this.messages = []
      }
      this.currentChat = supportChat
      Http.get('/chat/user-click-to-support-btn')
    },

    getUserName(chat) {
      const user = this.$store.state.auth.user.id === chat.ds_manager.id ? chat.lc_manager : chat.ds_manager
      return user.first_name + ' ' + (user.last_name ? user.last_name : '')
    },

    getCompanyName(chat) {
      const user = this.$store.state.auth.user.id === chat.ds_manager.id ? chat.lc_manager : chat.ds_manager
      if (user.dealership) {
        return user.dealership[0].name
      } else {
        return user.leasing_company[0].name
      }
    },

    getCompanyLocation(chat) {
      const user = this.$store.state.auth.user.id === chat.ds_manager.id ? chat.lc_manager : chat.ds_manager
      if (user.dealership) {
        return user.dealership[0].ds_location.address
      }
      return ''
    },

    ...CommonMethods
  }
}
</script>
<style>
  .chat-message-sheet {
    overflow-y: auto;
  }
</style>
